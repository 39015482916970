import { useEffect, useState } from 'react';
import Ad from './Ad';
import type { Ad as AdType } from '@/types';

export default function ShowAd({ ad, index, total }: { ad: AdType; index: number; total: number }) {
  const [gridCols, setGridCols] = useState(2);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 768px)');
    setGridCols(mql.matches ? 2 : 5);
    const onMqlChange = (e: MediaQueryListEvent) => setGridCols(e.matches ? 2 : 5);
    mql.addEventListener('change', onMqlChange);

    return () => mql.removeEventListener('change', onMqlChange);
  }, []);

  const count = index + 1;
  const row = Math.ceil(count / gridCols);
  const isTopAd = count <= gridCols && count % gridCols === 0;
  const isBottomAd = row === 4 && (count === total || count / gridCols / 4 === 1);

  if (isTopAd || isBottomAd) {
    return (
      <div className="w-full col-span-full">
        <Ad ad={ad} placement={isTopAd ? 'top' : 'bottom'} />
      </div>
    );
  }

  return null;
}
